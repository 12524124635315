<template>
  <div>
    <b-card
      title = "Add New Package"
      class="border p-2"
    >

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form 
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
          <b-row>

            <!-- Field: Full Name -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Package"
                rules="required"
              >
                <b-form-group
                  label="Package Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>

                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>
          <b-row>

            <!-- Field: Price -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Price"
                rules="required"
              >
                <b-form-group
                  label="Pricing Plan"
                  label-for="price"
                > 
                  <b-input-group >
                    <b-input-group-prepend is-text>
                      <feather-icon
                        icon="DollarSignIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-prepend>

                    <b-form-input
                      id="price"
                      v-model.number="form.price"
                      step="0.05" 
                      type="number"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-input-group-append  is-text>
                        <b-form-radio-group
                          id="duration"
                          v-model="form.duration"
                          :options="planOptions"
                          value="Monthly"
                        />
                    </b-input-group-append>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- modules TABLE -->
          <b-card
            no-body
            class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="LockIcon"
                  size="18"
                />
                <span class="align-middle ml-50">Choose Modules</span>
              </b-card-title>
            </b-card-header>

            <validation-provider
                #default="validationContext"
                name="modulesData"
                rules="required"
              >

              <div class="align-middle ml-50 pl-1">
                <b-form-group>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="form.module_ids"
                    :options="modulesData"
                    value-field="id"
                    text-field="name"
                    name="flavour-1"
                    class="demo-inline-spacing"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </div>

            </validation-provider>

          </b-card>

          <!-- Action Buttons -->
          <b-row>

            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                variant="outline-secondary"
                :to="{name: 'package-list'}"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormCheckboxGroup, BFormInvalidFeedback, BFormRadioGroup, BInputGroupPrepend, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import packageStoreModule from '../packageStoreModule'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInvalidFeedback, 
    BFormRadioGroup, 
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    onSubmit() {

      store.dispatch('app-package/addData', this.form)
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
            this.$router.push({name: 'package-list'})
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
      })
    }
  },
  setup(props) {
    var modulesData = ref([])

    const PACKAGE_APP_STORE_MODULE_NAME = 'app-package'

    // Register module
    if (!store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.registerModule(PACKAGE_APP_STORE_MODULE_NAME, packageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PACKAGE_APP_STORE_MODULE_NAME)
    })

    const getModulesData = async () => {
      store.dispatch('app-package/fetchModuleOption')
        .then((response) => {
          if(response.data.status == 1)
            modulesData.value = response.data.data
      })
    }

    onMounted(getModulesData)


    const form = {
      name: '',
      price: '0',
      duration: 'Month',
      module_ids: [],
    }

    const resetData = () => {
      form
    }

    const planOptions = [
      { text: 'Monthly', value: 'Month' },
      { text: 'Yearly', value: 'Year' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      form,
      planOptions,
      modulesData,
      getModulesData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.input-group-append .input-group-text {
    border-right: 1px solid #d8d6de !important;
}
</style>
